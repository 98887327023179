import React, { useState } from "react";
import Header from "../components/header";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import Cookies from "js-cookie";
import { role } from "../redux/modules/userSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3.5),
    backgroundColor: "rgba(242, 248, 255, 1)",
    // backgroundColor: "#f2f8ff",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1.5),
    borderTop: "1px solid rgba(0, 0, 0, 0.5)",
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiDialog-paper": {
      width: "100%", // Takes the full width of the screen on small devices
      margin: theme.spacing(1), // Small margin from the edges
    },
  },
  // For medium devices
  [theme.breakpoints.between("sm", "md")]: {
    "& .MuiDialog-paper": {
      width: "80%", // Takes 80% of the screen width on medium devices
    },
  },
  // For large devices and up
  [theme.breakpoints.up("lg")]: {
    "& .MuiDialog-paper": {
      width: "100%", // Takes 60% of the screen width on large devices
    },
  },
}));

const UserDetailsPreview = ({
  openPreview,
  handlePreviewChange,
  formValues,
  user_id,
  accessType,
  submitted,
  setSubmitted,
  snackbarSeverity,
  snackbarmessage,
  handleSnackbarClose,
  selectedCompanies,
  selectedBranches,
  createUser,
  updateUser,
  openSnackbar,
  action,
  role,
  selectedAdmin,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getPermissionText = (accessType) => {
    if (accessType.add && accessType.edit) {
      return "Add/Edit";
    }
    if (accessType.view) {
      return "View Only";
    }
    return "";
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (searchParams.get("edit") === "1") {
      updateUser();
    } else {
      createUser();
    }
  };
  const managedBranchArr = selectedBranches.filter(
    (branch) => branch.is_branch_managed === 1
  );
  return (
    <>
      <BootstrapDialog
        maxWidth="md"
        // onClose={handleClosePost}
        aria-labelledby="customized-dialog-title"
        open={openPreview}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          User Preview Information
        </DialogTitle>
        <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.5)" }} />
        <IconButton
          aria-label="close"
          onClick={handlePreviewChange}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: "inherit",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box display={"flex"} flexDirection={"column"} gap={2.5}>
            {/* basicInfo */}
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: 3,
              }}
            >
              <Box sx={{ p: 1, px: 2 }}>
                <Typography variant="h6" fontWeight={500}>
                  Basic Info
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{ p: 1, px: 2 }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    First Name
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {formValues.firstName}
                  </Typography>
                </Box>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Last Name
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {formValues.lastName ? formValues.lastName : "NA"}
                  </Typography>
                </Box>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Email
                  </Typography>
                  <Typography variant="body1">{formValues.email}</Typography>
                </Box>
              </Box>
            </Box>

            {/* menu_permission */}

            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: 3,
              }}
            >
              <Box sx={{ p: 1, px: 2 }}>
                <Typography variant="h6" fontWeight={500}>
                  Access Type
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{ p: 1, px: 2 }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                {/* <Box display={"flex"} gap={2} alignItems={"center"}>
                                    <Typography
                                        sx={{ color: "gray", width: "25%" }}
                                        fontWeight={500}
                                        variant="body1"
                                    // 200 for comapny

                                    >
                                        Company
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            getPermissionText(accessType[200])
                                        }
                                    </Typography>
                                </Box>
                                <Box display={"flex"} gap={2} alignItems={"center"}>
                                    <Typography
                                        sx={{ color: "gray", width: "25%" }}
                                        fontWeight={500}
                                        variant="body1"
                                    // 500 for branch
                                    >
                                        Branch
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            getPermissionText(accessType[500])
                                        }
                                    </Typography>
                                </Box>
                                <Box display={"flex"} gap={2} alignItems={"center"}>
                                    <Typography
                                        sx={{ color: "gray", width: "25%" }}
                                        fontWeight={500}
                                        variant="body1"
                                    // 400 for job
                                    >
                                        Job
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            getPermissionText(accessType[400])
                                        }
                                    </Typography>
                                </Box>
                                <Box display={"flex"} gap={2} alignItems={"center"}>
                                    <Typography
                                        sx={{ color: "gray", width: "25%" }}
                                        fontWeight={500}
                                        variant="body1"
                                    // 100 for candidate
                                    >
                                        Candidate
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            getPermissionText(accessType[100])
                                        }
                                    </Typography>
                                </Box> */}
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Role Type
                  </Typography>
                  <Typography variant="body1">{role}</Typography>
                </Box>
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Assigned Under
                  </Typography>
                  <Typography variant="body1">{selectedAdmin}</Typography>
                </Box>
              </Box>
            </Box>

            {/* company selection daata */}

            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: 3,
              }}
            >
              <Box sx={{ p: 1, px: 2 }}>
                <Typography variant="h6" fontWeight={500}>
                  Company
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{ p: 1, px: 2 }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Box display={"flex"} gap={2}>
                  <Typography
                    sx={{ color: "gray", width: "25%" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Selected
                  </Typography>
                  <Typography sx={{ width: "75%" }} variant="body1">
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent={"start"}
                      alignContent={"start"}
                      alignItems={"start"}
                      gap={1}
                    >
                      {selectedCompanies.map((company, index) => (
                        <>
                          {company === -100 ? (
                            <Chip
                              label={"All Companies"}
                              color="primary"
                              sx={{
                                backgroundColor: "rgba(130, 130, 130, 1)",
                                borderRadius: 2,
                              }}
                            />
                          ) : (
                            <Chip
                              label={company.name}
                              color="primary"
                              sx={{
                                backgroundColor: "rgba(130, 130, 130, 1)",
                                borderRadius: 2,
                                textTransform: "capitalize",
                              }}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* branch selection data */}

            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                boxShadow: 3,
              }}
            >
              <Box sx={{ p: 1, px: 2 }}>
                <Typography variant="h6" fontWeight={500}>
                  Branch
                </Typography>
              </Box>
              <Divider />
              {managedBranchArr.length < 1 ? (
                <Box
                  sx={{ p: 1, px: 2 }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Box display={"flex"} gap={2}>
                    <Typography
                      sx={{ color: "gray" }}
                      fontWeight={500}
                      variant="body1"
                    >
                      The selected{" "}
                      {selectedCompanies.length > 1 ? "companies" : "company"}{" "}
                      have no branches.
                    </Typography>
                  </Box>
                </Box>
              ) : selectedBranches.length < 1 ? (
                <Box
                  sx={{ p: 1, px: 2 }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Box display={"flex"} gap={2}>
                    <Typography
                      sx={{ color: "gray", width: "25%" }}
                      fontWeight={500}
                      variant="body1"
                    >
                      Selected
                    </Typography>
                    <Typography sx={{ width: "75%" }} variant="body1">
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent={"start"}
                        alignContent={"start"}
                        alignItems={"start"}
                        gap={1}
                      >
                        <Chip
                          label={"All Branches"}
                          color="primary"
                          sx={{
                            backgroundColor: "rgba(130, 130, 130, 1)",
                            borderRadius: 2,
                          }}
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                selectedBranches.map((branch, index) => (
                  <>
                    {branch.is_branch_managed === 1 && (
                      <Box
                        sx={{ p: 1, px: 2 }}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={2}
                      >
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                          <Typography
                            sx={{
                              color: "gray",
                              width: "25%",
                              textTransform: "capitalize",
                            }}
                            fontWeight={500}
                            variant="body1"
                          >
                            {branch.company_name}
                          </Typography>
                          <Typography sx={{ width: "75%" }} variant="body1">
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              justifyContent={"start"}
                              alignContent={"start"}
                              alignItems={"start"}
                              gap={1}
                            >
                              {branch.branches.map((branch, index) =>
                                branch === -100 ? (
                                  <Chip
                                    label={"All Branches"}
                                    color="primary"
                                    sx={{
                                      backgroundColor: "rgba(130, 130, 130, 1)",
                                      borderRadius: 2,
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    label={branch.branch_name}
                                    color="primary"
                                    sx={{
                                      backgroundColor: "rgba(130, 130, 130, 1)",
                                      borderRadius: 2,
                                      textTransform: "capitalize",
                                    }}
                                  />
                                )
                              )}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                ))
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <Typography
              display={"flex"}
              gap={2.5}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={handlePreviewChange}
                sx={{ px: 3, textTransform: "capitalize" }}
              >
                Edit
              </Button>
              <Button variant="contained" size="large" onClick={handleSubmit}>
                Add
              </Button>
            </Typography>
          </Box>
        </DialogActions>
      </BootstrapDialog>
      <Snackbar
        open={submitted}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={snackbarSeverity === "error" ? 3000 : 1500}
        onClose={handleSnackbarClose}
        sx={{ zIndex: 99999999999 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
          severity={snackbarSeverity === "error" ? "error" : "success"}
        >
          {snackbarmessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserDetailsPreview;
