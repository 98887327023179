import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Autocomplete,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Stack,
  Snackbar,
  OutlinedInput,
  InputAdornment,
  Alert,
  Checkbox,
  FormGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormHelperText from "@mui/material/FormHelperText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";
import Screeningquestion from "./Screeningquestion";
import Cookies from "js-cookie";
import { min } from "@antv/util";

// const baseURL = process.env.REACT_APP_BASEURL;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiDialog-paper": {
      width: "100%", // Takes the full width of the screen on small devices
      margin: theme.spacing(1), // Small margin from the edges
    },
  },

  // For medium devices
  [theme.breakpoints.between("sm", "md")]: {
    "& .MuiDialog-paper": {
      width: "80%", // Takes 80% of the screen width on medium devices
    },
  },

  // For large devices and up
  [theme.breakpoints.up("lg")]: {
    "& .MuiDialog-paper": {
      width: "100%", // Takes 60% of the screen width on large devices
    },
  },
}));

const editorConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  placeholder: "Write the Description here...",
};

const myTypes = {
  Hire: "H",
  Contract: "C",
  "Contract to Hire": "C2H",
  Freelance: "F",
};

const myTypesReverse = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const Createjobdrawer = ({
  editFlag,
  jobId,
  isDrawerOpen,
  setDrawerOpen,
  toggleDrawer,
  setIsSuccess,
  isEdit,
  setIsEdit,
}) => {
  const navigate = useNavigate();
  const [openDescription, setOpenDescription] = useState(false);
  const [skills, setSkills] = useState([]);
  const [openPost, setOpenPost] = useState(false);
  const [editorData, setEditorData] = useState("");
  // const [department, setDepartment] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [jobopenings, setJobopenings] = useState("");
  const [duration, setJobduration] = useState("");
  const [rate, setRate] = useState("");
  const [salary, setSalary] = useState("");
  const [minexp, setMinexp] = useState("");
  const [maxexp, setMaxexp] = useState("");
  const [singleworkmode, setSingleworkmode] = useState("On-Site");
  const [category, setCategory] = useState([]);
  const [singleCategory, setSingleCategory] = useState("");
  const [jobType, setJobType] = useState("");
  const [workmode, setWorkmode] = useState([]);
  const [states, setStates] = useState([]);
  const [singlestate, setSingleState] = useState("");
  const [cities, setCities] = useState([]);
  const [singlecities, setsinglecities] = useState("");
  const [notes, setNotes] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const getToken = useSelector((state) => state.user.token);
  const [jobTitleError, setJobTitleError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [companyError, setcompanyError] = useState("");
  const [branchError, setbranchError] = useState("");
  const [jobtypeError, setjobtypeError] = useState("");
  const [minexpError, setminexpError] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [skillsError, setskillError] = useState("");
  const [stateError, setstateError] = useState("");
  const [cityError, setcityError] = useState("");
  const { id } = useParams();
  const [maxexpError, setMaxexpError] = useState("");
  const [equalityError, setEqualityError] = useState("");
  const [cityarr, setcityarr] = useState([]);
  const [shouldRerunEffect, setShouldRerunEffect] = useState(false);
  const [SkillsOption, setSkillsOption] = useState([]);
  const [Logo, setLogo] = useState("");
  const [datemodified, setDateModified] = useState("");
  const [disableflag, setDisableFlag] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState([]);
  const [getError, setErrors] = useState([]);
  const [getErrorIndex, setIndex] = useState([]);
  const [emptyQuestionIndices, setEmptyQuestionIndices] = useState([]);
  const [newerror, setnewerror] = useState([]);
  const [company, setcompany] = useState("");
  const [companyoptions, setcompanyoptions] = useState([]);
  const [companyname, setcompanyname] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [branchOption, setbranchOption] = useState([]);
  const [branch, setbranch] = useState("");
  const [branch_name, setbranch_name] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [loading, setLoading] = useState(false);

  const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
  };

  // const logo = localStorage.getItem("logo");
  // const regexJobOpening = /^\d*$/;
  const handleJobTitleBlur = () => {
    if (jobtitle.length > 60) {
      setJobTitleError("Maximum 60 characters allowed");
    } else {
      setJobTitleError("");
    }
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (editFlag || shouldRerunEffect) {
      axios
        .get(`job-info/${jobId}`, {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        })
        .then((response) => {
          setDisableFlag(response.data.message.hasAnyoneApplied);
          const data = response.data.message.jobInfoResult[0];

          axios
            .post(process.env.REACT_APP_JOBEZEURL + "/state-city/list", {
              isoCode: data.city.includes("(")
                ? data.city.split("(")[1].split(")")[0]
                : data.city,
            })
            .then((response) => {
              if (data.city) {
                setCities(response.data.message);
                setcityarr(response.data.message.map((e) => e.city));
              } else {
                setStates(response.data.message);
              }
            });

          let skillArray = data?.skills ? data?.skills : [];
          setSkills(skillArray);
          setEditorData(data.description);
          // setDepartment(data.department);
          setJobtitle(data.title);
          setJobopenings(data.openings);
          setRate(data.rate);
          setSalary(data.salary);
          setMinexp(data.min_experience);
          setMaxexp(data.max_experience);
          setSingleworkmode(data.workmode);
          setSingleCategory(data.role);
          setcompany(data.company_id);
          setbranch(data.branch_id ? data.branch_id : "");
          setbranch_name(data.branch_name);
          setIsPublic(data.isPublic === 1 ? true : false);
          setcompany_name(data.name);
          setJobType(myTypesReverse[data.type]);
          setSingleState(data.state);
          setNotes(data.notes);
          setJobduration(data.duration);
          setsinglecities(data.city);
          setLogo(data.logo);
          setIsChecked(data.cover_letter_required);
          setDateModified(data.date_modified);
        })
        .catch((err) => { });
    }
  }, [isEdit]);

  useEffect(() => {
    // console.log("!!!!!!!!!!!!!!2222222222")
    axios
      .get(`all-category`)
      .then((response) => {
        setCategory(response.data.message.category);
        setWorkmode(response.data.message.workmode);
      })
      .catch((err) => {
        console.log(err);
      });
    const payload = {};
    //  console.log("253333333333333333333333333333333333",singlestate)
    //  console.log("$$$$",states)
    if (singlestate && states.length > 0) {
      // console.log("2544444444444444444444444444444444")
      const findIsoCode = states.filter((e) => e.name === singlestate);
      payload.isoCode = findIsoCode[0]?.isoCode;
      // console.log("payload",payload)
    }
    axios
      .post(process.env.REACT_APP_JOBEZEURL + "/state-city/list", payload)
      .then((response) => {
        if (payload.isoCode) {
          setCities(response.data.message);
          setcityarr(response.data.message.map((e) => e.city));
        } else {
          setStates(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [singlestate, isEdit]);

  const handlejobtitle = (e) => {
    const { value } = e.target;
    let formattedValue = value;

    // Convert the first character to uppercase
    if (value.length > 0) {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (formattedValue.length > 60) {
      setJobTitleError("Maximum 60 characters allowed");
    } else {
      setJobTitleError("");
    }
    setJobtitle(formattedValue);
    setJobTitleError("");
  };

  const handlejobopenings = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value === "") {
      setJobopenings();
      return;
    }

    const intValue = parseInt(value, 10);

    if (intValue > 1000) {
      // If the value is greater than 1000, set it back to the previous value
      setJobopenings(e.target.value.slice(0, -1)); // remove the last entered character
    } else {
      // Set the valid integer value
      setJobopenings(intValue.toString());
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text");
    const sanitizedValue = pastedValue.replace(/\D/g, "");
    const intValue = parseInt(sanitizedValue, 10);
    const finalValue = intValue > 1000 ? "1000" : intValue.toString();
    setJobopenings(finalValue);
  };

  const handleduration = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 20) {
      setJobduration(inputValue);
    }
  };

  const handlerate = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setRate(limitedValue);
  };

  const handlesalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setSalary(limitedValue);
  };
  const handleminexp = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "0");

    if (
      value === "" ||
      (/^0$|^[1-9]\d*$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) <= 40)
    ) {
      setMinexp(value);

      // // Check if minexp is equal to maxexp
      // if (maxexp !== "" && parseInt(value, 10) === parseInt(maxexp, 10)) {
      //   setminexpError("Minimum experience cannot be equal to maximum experience.");
      // } else {
      //   setminexpError("");
      // }

      // Additional check to clear maxexpError if minexp is valid
      // if (maxexp !== "" && parseInt(value, 10) < parseInt(maxexp, 10)) {
      //   setMaxexpError("");
      // }
      // else {
      //   setMaxexpError("Please enter a valid number between 1 and 40.");
      // }
    }
  };

  const handlemaxexp = (e) => {
    const value = e.target.value;

    if (
      value === "" ||
      (/^\d+$/.test(value) &&
        parseInt(value, 10) >= 1 &&
        parseInt(value, 10) <= 40)
    ) {
      setMaxexp(value);

      //   // Check if maxexp is equal to minexp
      //   if (minexp !== "" && parseInt(value, 10) === parseInt(minexp, 10)) {
      //     setMaxexpError("Maximum experience cannot be equal to minimum experience.");
      //     scrollToErrorField("minExp");
      //     scrollToErrorField("maxExp");
      //   } else {
      //     setMaxexpError("");
      //   }
      //   if (parseInt(minexp) > parseInt(maxexp)) {
      //     setminexpError("Minimum Experience cannot be greater than Maximum Experience.");
      //     setMaxexpError("Maximum Experience cannot be less than Minimum Experience.");
      //     scrollToErrorField("minExp");
      //     scrollToErrorField("maxExp");
      //     window.scrollTo(0, 0);
      //     return;
      //   }
      // Additional check to clear minexpError if maxexp is valid
      //   if (minexp !== "" && parseInt(value, 10) > parseInt(minexp, 10)) {
      //     setminexpError("");
      //   }
      // } else {
      //   setMaxexpError("Please enter a valid number between 1 and 40.");
    }
  };

  const handleBlurMinExp = () => {
    if (parseInt(minexp, 10) === parseInt(maxexp, 10)) {
      setminexpError(
        "Minimum experience cannot be equal to maximum experience."
      );
    } else {
      setminexpError("");
    }
  };

  const handleBlurMaxExp = () => {
    if (parseInt(maxexp, 10) < parseInt(minexp, 10)) {
      setMaxexpError(
        "Maximum experience cannot be less than minimum experience."
      );
    } else if (parseInt(maxexp, 10) === parseInt(minexp, 10)) {
      setMaxexpError(
        "Maximum experience cannot be equal to minimum experience."
      );
    } else {
      setMaxexpError("");
    }
  };

  const singlehandleworkmode = (e) => {
    setSingleworkmode(e.target.value);
  };

  const handleCategory = (event) => {
    setSingleCategory(event.target.value);
    setCategoryError("");
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const handlecompany = (event) => {
    let tmplogo = companyoptions.find(
      (option) => option.id === event.target.value
    )?.logo;
    // console.log("logo", tmplogo);
    setLogo(tmplogo);
    setcompany(event.target.value);
    setbranch("");
    setbranchOption([]);
    setbranchError("");
    setIsBranchManaged(null);
    setcompanyError("");
  };

  const handleBranch = (event) => {
    // let tmplogo = branchOption.find((option) => option.id === event.target.value)?.logo
    // console.log("logo",tmplogo)
    // setLogo(tmplogo)
    setbranch(event.target.value);
    setbranchError("");
    // setcompanyError("");
  };

  useEffect(() => {
    // Fetch brach options when company selected
    if (company) {
      try {
        axios
          .post(
            `/list-of-branches/${company}`,
            {},
            {
              headers: {
                authorization:
                  Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
              },
            }
          )
          .then((response) => {
            setbranchOption(response.data.message.branchListResponse);
          })
          .catch((error) => {
            console.error("Error fetching highest education options:", error);
          });
      } catch (error) {
        console.error("Error fetching highest education options:", error);
      }
    }
  }, [company]);

  useEffect(() => {
    // Fetch company options

    axios
      .post(
        `/list-of-companies`,
        {},
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        setcompanyoptions(response.data.message.companyListResponse);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });
  }, [editFlag]);

  const handleJobType = (event) => {
    setJobType(event.target.value);
    if (
      event.target.value === "Contract" ||
      event.target.value === "Contract to Hire" ||
      event.target.value === "Freelance"
    ) {
      setSalary("");
    }
    if (event.target.value === "Hire") {
      setJobduration("");
    }
    setjobtypeError("");
  };
  const handleStates = (event) => {
    setSingleState(event.target.value);
    setsinglecities("");
    setstateError("");
  };
  const handleCities = (event) => {
    setsinglecities(event.target.value);
    setcityError("");
  };
  const handleSkillChange = (_, newValues) => {
    // Extract labels from objects if needed
    const labels = newValues?.map((item) =>
      typeof item === "object" ? item.label : item
    );

    // Filter out empty strings and use Set to remove duplicates
    const uniqueSkills = Array.from(
      new Set(
        labels
          ?.filter(skill => skill.trim() !== "") // Remove empty skills
          .map(skill => skill.trim().toLowerCase()) // Normalize to lowercase
      )
    );

    // Update the state with unique skills
    setSkills(uniqueSkills);
    setskillError("");
  };


  const availableOptions = SkillsOption.filter(
    (option) => !skills.includes(option.label.toLowerCase())
  );

  // console.log("skills",skills)
  // console.log("availableOptions",availableOptions, SkillsOption)

  const renderTags = (value, getTagProps) => {
    if (!value || value.length === 0) {
      return null; // Return null if value is empty or undefined
    }

    return value.map((option, index) => (
      <Chip
        key={index}
        variant="filled"
        label={option}
        {...getTagProps({ index })}
        onDelete={() => {
          const updatedSkills = skills?.filter((skill) => skill !== option);
          setSkills(updatedSkills);
        }}
      />
    ));
  };


  const handlenotes = (e) => {
    setNotes(e.target.value);
  };

  const handleOpenPost = () => {
    setnewerror(emptyQuestionIndices);

    // Error handling for experience fields
    let hasError = false; // Flag to track if there are any errors

    if (maxexp === "" && minexp === "") {
      setMaxexpError("Maximum experience is required.");
      setminexpError("Minimum experience is required.");
      scrollToErrorField("maxExp");
      scrollToErrorField("minExp");
      window.scrollTo(0, 0);
      hasError = true; // Set error flag
    } else if (minexp === "") {
      setminexpError("Minimum Experience required.");
      scrollToErrorField("minExp");
      window.scrollTo(0, 0);
      hasError = true; // Set error flag
    } else if (maxexp === minexp) {
      setminexpError(
        "Minimum experience cannot be equal to maximum experience."
      );
      setMaxexpError(
        "Minimum experience cannot be equal to maximum experience."
      );
      scrollToErrorField("minExp");
      window.scrollTo(0, 0);
      hasError = true; // Set error flag
    } else if (parseInt(minexp) > parseInt(maxexp)) {
      setminexpError(
        "Minimum Experience cannot be greater than Maximum Experience."
      );
      setMaxexpError(
        "Maximum Experience cannot be less than Minimum Experience."
      );
      scrollToErrorField("minExp");
      scrollToErrorField("maxExp");
      window.scrollTo(0, 0);
      hasError = true; // Set error flag
    } else if (parseInt(minexp) > 40) {
      setminexpError("Minimum Experience cannot be greater than 40.");
      scrollToErrorField("minExp");
      window.scrollTo(0, 0);
      hasError = true; // Set error flag
    } else {
      setminexpError(""); // Clear the error if the field is valid
      setMaxexpError(""); // Clear maxexpError if it was set
    }

    // Check if jobtitle is empty
    if (jobtitle.trim() === "") {
      setJobTitleError("Job Title is required.");
      scrollToErrorField("jobtitle");
    } else {
      setJobTitleError(""); // Clear the error if the field is valid
    }

    if (singleCategory === "") {
      setCategoryError("Category is required.");
      scrollToErrorField("category");
    } else {
      setCategoryError(""); // Clear the category error if the field is valid
    }

    if (company === "") {
      setcompanyError("Company is required.");
      scrollToErrorField("company");
    } else {
      setcompanyError("");
    }

    if (branch === "" && isBranchManaged === 1) {
      setbranchError("Branch is required.");
    } else {
      setbranchError("");
    }

    if (jobType === "") {
      setjobtypeError("Job Type is required");
      scrollToErrorField("jobType");
    } else {
      setjobtypeError("");
    }

    // if (minexp === "") {
    //   setminexpError("Minimum Experience required.");
    // } else if (parseInt(minexp) > parseInt(maxexp)) {
    //   setminexpError("Minimum Experience cannot be greater than 40");
    // } else {
    //   setminexpError(""); // Clear the error if the field is valid
    // }

    if (editorData.trim() === "") {
      setdescriptionError("Description is required.");
    } else {
      setdescriptionError(""); // Clear the error if the field is valid
    }

    // if (skills?.length === 0) {
    //   setskillError("Skills are required");
    // } else {
    //   setskillError("");
    // }

    if (skills?.length === 0) {
      setskillError("Skills are required");
      scrollToErrorField("skills");
    } else if (skills.length < 5) {
      scrollToErrorField("skills");
      setskillError("Minimum 5 skills required");
    } else {
      setskillError("");
    }

    // console.log(
    //   " aa gayi cityyyyyyyyyyyyyyyyyyyyyyyyyyy",
    //   singlecities,
    //   typeof singlecities,
    //   cityarr.includes(singlecities)
    // );
    if (
      !singlestate ||
      (Object.keys(singlestate) && Object.keys(singlestate).length === 0)
    ) {
      setstateError("State is required");
      scrollToErrorField("state");
    } else {
      setstateError("");
    }

    if (!cityarr.includes(singlecities)) {
      setcityError("City is required");
      scrollToErrorField("city");
    } else {
      setcityError("");
    }
    // console.log("singlestate", singlestate);

    // Only set OpenPost if there are no errors
    if (!hasError) {
      if (
        jobtitle &&
        jobtitle.trim() !== "" &&
        singleCategory !== "" &&
        company !== "" &&
        jobType !== "" &&
        minexp !== "" &&
        editorData !== "" &&
        skills.length !== 0 &&
        skills.length >= 5 &&
        singlestate &&
        Object.keys(singlestate)?.length !== 0 &&
        singlecities !== "" &&
        emptyQuestionIndices.length === 0 &&
        minexpError.trim() === "" && // Ensure no errors
        maxexpError.trim() === "" // Ensure no errors
      ) {
        setOpenPost(true);
      }
    }
    if (branch === "" && isBranchManaged === 1) {
      scrollToErrorField("branch");
      setOpenPost(false);
    }
  };

  const [isBranchManaged, setIsBranchManaged] = useState(null);

  useEffect(() => {
    const selectedCompany = companyoptions.find(
      (companyName) => companyName.id === company
    );
    if (selectedCompany) {
      setIsBranchManaged(selectedCompany.is_branch_managed);
    } else {
      setIsBranchManaged(null);
    }
  }, [company, companyoptions]);

  const scrollToErrorField = (fieldName) => {
    const errorFieldRef = errorFieldRefs[fieldName];
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const errorFieldRefs = {
    branch: useRef(null),
    jobtitle: useRef(null),
    category: useRef(null),
    jobType: useRef(null),
    skills: useRef(null),
    state: useRef(null),
    city: useRef(null),
    maxExp: useRef(null),
    minExp: useRef(null),
    // Add similar refs for other fields...
  };

  // useEffect(() => {
  //   axios
  //     .get(`all-skills`)
  //     .then((response) => {
  //       setSkillsOption(response.data.message);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const hanldeSkillInputChange = _debounce((value) => {
    // Call your API search function here (skillssearch)
    skillssearch(value);
  }, 415);

  const skillssearch = async (val) => {
    try {
      if (val) {
        const response = await axios.get(
          process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`
        );
        // console.log("response", response);
        setSkillsOption(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handlePostJob = () => {
    // const companyId = localStorage.getItem("company_id");
    setLoading(true);
    axios
      .post(
        `create-job`,
        {
          // department :department,
          category: singleCategory,
          job_title: jobtitle,
          job_type: myTypes[jobType],
          no_of_opening: jobopenings == "0" || !jobopenings ? 1 : jobopenings,
          duration: duration,
          rate: rate,
          salary: salary,
          min_exp: minexp ? Number(minexp) : null,
          max_exp: maxexp ? Number(maxexp) : null,
          workmode: singleworkmode,
          city: singlecities,
          state: singlestate,
          job_desc: editorData,
          skills: skills,
          internal_notes: notes,
          companyId: company,
          branch_id: branch ? branch : null,
          cover_letter_required: isChecked ? 1 : 0,
          questionsArray: formData,
          isPublic: isPublic ? 1 : 0,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        // console.log(response,"himanshuuuuuuu")
        if (response.data.status === 200) {
          setLoading(false);
          // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
          setOpenSnackbar(true);
          setSnackbarMessage("Job Created Successfully");
          setTimeout(() => {
            navigate("/jobOrders?page=1");
          }, 1000);
          // setIsSuccess(true);
          setIsChecked(false);
          handleClosePost();
          setDrawerOpen(false);
          setOpenSnackbar(true);
          // setWorkmode([];
          setOpenDescription("");
          setEditorData("");
          // setDepartment("");
          setJobtitle("");
          setJobopenings("");
          setSkills([]);
          setcompany("");
          setRate("");
          setSalary("");
          setMinexp("");
          setMaxexp("");
          // setSingleworkmode("");
          setSingleCategory("");
          setJobType("");
          setSingleState("");
          setNotes("");
          setJobduration("");
          setsinglecities("");
          setOpenPost(false);
          // setDrawerOpen(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleEditJob = () => {
    setLoading(true);
    axios
      .post(
        `update-job/${jobId}`,
        {
          // department :department,
          category: singleCategory,
          job_title: jobtitle,
          job_type: myTypes[jobType],
          no_of_opening: jobopenings == "0" || !jobopenings ? 1 : jobopenings,
          duration: duration,
          rate: rate,
          salary: salary,
          min_exp: minexp ? Number(minexp) : 0,
          max_exp: maxexp ? Number(maxexp) : 0,
          workmode: singleworkmode,
          city: singlecities,
          state: singlestate,
          job_desc: editorData,
          skills: skills,
          internal_notes: notes,
          cover_letter_required: isChecked ? 1 : 0,
          original_date_modified: datemodified,
          questionsArray: formData,
          companyId: company,
          branch_id: branchOption.length > 0 ? branch : null,
          isPublic: isPublic ? 1 : 0,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        // console.log("response",response)
        if (response.data.status === 200) {
          setLoading(false);
          setOpenSnackbar(true);
          setSnackbarMessage("Job Edited Successfully");
          setTimeout(() => {
            navigate(`/jobOrders/${jobId}`, {
              state: {
                value: 0,
              },
            });
          }, 1000);
          setOpenPost(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err.response.data.error.error);
        setSnackbarMessage(err.response.data.error.error);
      });
  };

  const handleClosePost = () => {
    setOpenPost(false);
  };

  const handlecancel = () => {
    // setSingleCategory('')
    toggleDrawer(false);
  };
  const handleOpenDescription = () => {
    setOpenDescription(true);
  };
  const handleCloseDescription = () => {
    setOpenDescription(false);
  };

  const handleSubmitJobDescription = () => {
    setOpenDescription(false);
  };

  const handleEditorChange = (event, editor) => {
    // console.log(editor.getData(), "data test");
    setEditorData(editor.getData());
    setdescriptionError("");
  };

  const handleCancelData = () => {
    setSingleCategory("");
    setCategoryError("");
    setcompany("");
    setcompanyError("");
    setbranchError("");
    setJobtitle("");
    setJobTitleError("");
    setJobType("");
    setjobtypeError("");
    setJobduration("");
    setJobopenings("");
    setRate("");
    setskillError("");
    setMaxexp("");
    setMinexp("");
    setminexpError("");
    setSingleState("");
    setstateError("");
    setsinglecities("");
    setcityError("");
    setSalary("");
    setSkills("");
    setEditorData("");
    setdescriptionError("");
    navigate("/jobOrders?page=1");
    // setDrawerOpen(false);
    setShouldRerunEffect((prev) => !prev);
    // setDrawerOpen(false);
    setShouldRerunEffect((prev) => !prev);
  };

  useEffect(() => {
    const company_name = companyoptions?.filter(
      (data) => data.company_id == company
    )[0]?.name;
    const branch_name = branchOption?.filter(
      (data) => data.branch_id == branch
    )[0]?.name;
    setcompany_name(company_name);
    setbranch_name(branch_name);
  }, [company, branch]);

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      // Calling the appropriate function based on the editFlag
      if (editFlag) {
        handleEditJob();
      } else {
        handlePostJob();
      }
    }
  };
  return (
    <div>
      <Box
        // onClose={toggleDrawer(false)}
        // setDrawerOpen={setDrawerOpen}
        width={"100%"}
        sx={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", borderRadius: 5 }}
      >
        <Box>
          <Typography
            sx={{
              p: { xs: 1, sm: 2, md: 2, lg: 2 },
            }}
          >
            <Typography
              fontWeight={500}
              fontSize={"22px"}
              variant="body1"
              ref={errorFieldRefs.category}
            >
              {editFlag ? " Edit Job " : "Create a Job"}
            </Typography>
            {/* <CloseIcon onClick={handleCancelData} sx={{ cursor: "pointer" }} /> */}
          </Typography>
          <Divider />
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              maxWidth={1000}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                py: { xs: 1, sm: 2, md: 2, lg: 2 },
                px: { xs: 1, sm: 2, md: 2, lg: 2 },
              }}
            >
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  p: 0,
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={categoryError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    Category{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singleCategory}
                    size="small"
                    label="category"
                    onChange={handleCategory}
                    ref={errorFieldRefs.jobtitle}
                  >
                    {category &&
                      category?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {categoryError ? (
                    <FormHelperText>{categoryError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Typography>
              <FormControl
                size="small"
                fullWidth
                error={companyError ? true : false}
              >
                <InputLabel size="small" id="demo-simple-select-label">
                  Company
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={company}
                  size="small"
                  label="Company"
                  ref={errorFieldRefs.company}
                  onChange={handlecompany}
                >
                  {companyoptions.map((options) => (
                    <MenuItem className="fw-bold" value={options.id}>
                      {options.name}
                    </MenuItem>
                  ))}
                </Select>
                {companyError ? (
                  <FormHelperText>{companyError}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
              {branchOption.length > 0 && (
                <FormControl
                  size="small"
                  fullWidth
                // error={companyError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    Branch
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={branch}
                    size="small"
                    ref={errorFieldRefs.branch}
                    error={branchError ? true : false}
                    label="Branch"
                    onChange={handleBranch}
                  >
                    {branchOption.map((options) => (
                      <MenuItem className="fw-bold" value={options.id}>
                        {options.branch_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {branchError ? (
                    <FormHelperText error>{branchError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              <TextField
                id="outlined-basic"
                size="small"
                fullWidth
                label="Job Title"
                variant="outlined"
                value={jobtitle}
                error={jobTitleError !== ""}
                helperText={jobTitleError}
                onChange={handlejobtitle}
                onBlur={handleJobTitleBlur}
              />
              <Typography
                variant="body2"
                component="div"
                sx={{ display: "flex", gap: { xs: 1, sm: 2, md: 2, lg: 2 } }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={jobtypeError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    Job Type{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jobType}
                    size="small"
                    label="jobType"
                    onChange={handleJobType}
                    ref={errorFieldRefs.jobType}
                  >
                    <MenuItem value={"Hire"}>Hire</MenuItem>
                    <MenuItem value={"Contract"}>Contract</MenuItem>
                    <MenuItem value={"Contract to Hire"}>
                      Contract to Hire
                    </MenuItem>
                    <MenuItem value={"Freelance"}>Freelance</MenuItem>
                  </Select>
                  {jobtypeError && (
                    <FormHelperText>{jobtypeError}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  size="small"
                  label="No. of Openings"
                  variant="outlined"
                  fullWidth
                  value={jobopenings}
                  onChange={handlejobopenings}
                  onPaste={handlePaste}
                />
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ display: "flex", gap: { xs: 1, sm: 2, md: 2, lg: 2 } }}
              >
                <TextField
                  id="duration"
                  size="small"
                  fullWidth
                  label="Duration"
                  variant="outlined"
                  value={duration}
                  onChange={handleduration}
                  disabled={jobType === "Hire" ? true : false}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Rate/Hour
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Rate/Hour"
                    size="small"
                    variant="outlined"
                    value={rate}
                    onChange={handlerate}
                  />
                </FormControl>
              </Typography>
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Salary/Annum
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Salary/Annum"
                  value={salary}
                  size="small"
                  // type="number"
                  onChange={handlesalary}
                  disabled={jobType === "Hire" ? false : true}
                // inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                />
              </FormControl>

              <Typography variant="h6" component="div">
                Experience (Years)
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <TextField
                  id="minExp"
                  size="small"
                  fullWidth
                  label="Minimum Experience"
                  variant="outlined"
                  ref={errorFieldRefs.minExp}
                  value={minexp}
                  error={minexpError !== ""}
                  helperText={minexpError || " "} // Adding space to maintain height
                  onChange={handleminexp}
                  onBlur={handleBlurMinExp}
                  onFocus={(e) => setminexpError("")}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      height: "1.5em", // Fixed height for consistent alignment
                    },
                  }}
                />
                <Typography sx={{ height: "1.5em", marginBottom: "16px" }}>-</Typography>
                <TextField
                  id="maxExp"
                  size="small"
                  fullWidth
                  label="Maximum Experience"
                  variant="outlined"
                  ref={errorFieldRefs.maxExp}
                  value={maxexp}
                  error={maxexpError !== "" || equalityError !== ""}
                  helperText={maxexpError || equalityError || " "} // Adding space to maintain height
                  onChange={handlemaxexp}
                  onBlur={handleBlurMaxExp}
                  onFocus={(e) => setMaxexpError("")}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      height: "1.5em", // Fixed height for consistent alignment
                    },
                  }}
                />
              </Typography>

              <Box display={"flex"} gap={15}>
                <Box>
                  <Typography variant="h6" component="div">
                    Work Mode
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={singleworkmode}
                      name="radio-buttons-group"
                      onChange={singlehandleworkmode}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: { xs: 1, sm: 2, md: 3, lg: 3 },
                        }}
                      >
                        {workmode &&
                          workmode?.map((item, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                sx={{ m: 0, p: 0 }}
                                value={item?.name}
                                control={<Radio />}
                                label={item?.name}
                              />
                            );
                          })}
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <Typography variant="h6" component="div">
                    Visibility
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPublic}
                          onChange={handlePublicChange}
                        />
                      }
                      label={"Public"}
                    />
                  </FormGroup>
                </Box>
              </Box>

              <Typography variant="h6" component="div">
                Location
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  display: "flex",
                  p: 0,
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  error={stateError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singlestate}
                    size="small"
                    label="State"
                    onChange={handleStates}
                    ref={errorFieldRefs["state"] || null}
                  >
                    {states?.length > 0 &&
                      states.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {stateError ? (
                    <FormHelperText>{stateError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                <FormControl
                  size="small"
                  fullWidth
                  error={cityError ? true : false}
                >
                  <InputLabel size="small" id="demo-simple-select-label">
                    {" "}
                    City{" "}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={singlecities}
                    size="small"
                    label="City"
                    onChange={handleCities}
                  >
                    {cities &&
                      cities?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item?.city}>
                            {item?.city}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {cityError ? (
                    <FormHelperText>{cityError}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  display: "flex",
                  gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                  my: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                Job Description
              </Typography>
              <Box>
                <CKEditor
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={handleEditorChange}
                  config={editorConfig}
                  className="ck-editorCustom"
                  onReady={(editor) => {
                    // console.log("editor.", editor);
                  }}
                  onBlur={(event, editor) => {
                    // console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor);
                  }}
                />
              </Box>
              {descriptionError ? (
                <div
                  className="app"
                  style={{
                    fontWeight: 400,
                    color: "#d32f2f",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {descriptionError}
                </div>
              ) : (
                ""
              )}

              <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                sx={{
                  "& .MuiOutlinedInput-root": { alignItems: "flex-start" },
                }}
                id="tags-standard"
                value={skills ? skills : []}
                // onChange={(e)=>skillssearch(e.target.value)}
                onChange={handleSkillChange}
                onInputChange={(event, newInputValue) => {
                  hanldeSkillInputChange(newInputValue);
                }}
                options={availableOptions?.map((ele) => ele.label)}
                renderTags={renderTags}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Skills"
                    multiline
                    fullWidth
                    rows={4}
                    size="large"
                    placeholder="Enter skills"
                    error={skillsError !== ""}
                    helperText={skillsError}
                    onFocus={(e) => setskillError("")}
                    ref={errorFieldRefs.skills}
                  />
                )}
              />
              <Typography variant="h5">Screening Questions</Typography>
              <Typography sx={{ marginTop: "-15px" }}>
                We recommend adding 3 or more questions. Applicants must answer
                each question.
              </Typography>
              <Box>
                <Typography>
                  <Screeningquestion
                    newerror={newerror}
                    setnewerror={setnewerror}
                    setEmptyQuestionIndices={setEmptyQuestionIndices}
                    emptyQuestionIndices={emptyQuestionIndices}
                    setFormData={setFormData}
                    jobId={jobId}
                    disableflag={disableflag}
                  />
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox checked={isChecked} onChange={handleChange} />
                }
                label="Cover Letter Required"
              />

              <TextField
                id="outlined-multiline-flexible"
                size="small"
                fullWidth
                label="Internal Notes"
                variant="outlined"
                multiline
                value={notes}
                onChange={handlenotes}
              />
            </Box>
            <Typography
              sx={{
                p: { xs: 1, sm: 2, md: 2, lg: 2 },
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                borderTop: "1px solid rgba(0, 0, 0, 0.24)",
              }}
            >
              <Button variant="text" color="inherit" onClick={handleCancelData}>
                {" "}
                Cancel{" "}
              </Button>
              {/* {console.log("fhnewkfneiwfn",minexpError,maxexpError)} */}
              <Button
                variant="contained"
                onClick={handleOpenPost}
                disabled={
                  minexpError.trim() !== "" || // Assuming minExp is the state for minimum experience
                  maxexpError.trim() !== "" // Assuming maxExp is the state for maximum experience
                }
              >
                {" "}
                Preview{" "}
              </Button>
            </Typography>
            <BootstrapDialog
              maxWidth="md"
              onClose={handleClosePost}
              aria-labelledby="customized-dialog-title"
              open={openPost}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Job Preview
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClosePost}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "inherit",
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ backgroundColor: "#F2F8FF" }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    p: { xs: 1, sm: 2, md: 3, lg: 3 },
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: 1, sm: 2, md: 3, lg: 3 },
                      mb: 2,
                    }}
                    gutterBottom
                  >
                    <Typography>
                      <img
                        src={Logo}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        alt="logo"
                      />
                    </Typography>
                    <Typography
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography>
                        <b>{jobtitle}</b>
                      </Typography>
                      <Typography>
                        <b>{company_name}</b>
                      </Typography>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ my: 3 }}>
                    <Typography
                      sx={{
                        px: { xs: 1, sm: 2, md: 2, lg: 2 },
                        py: { xs: 1, sm: 2, md: 2, lg: 2 },
                        backgroundColor: "#E6EFF7",
                        borderRadius: 2,
                      }}
                      gutterBottom
                    >
                      <Typography
                        variant="body1"
                        fontSize={"20px"}
                        component="div"
                      >
                        Highlights
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: { xs: 1, sm: 2, md: 2, lg: 15 },
                        }}
                      >
                        <Typography
                          variant="body1"
                          // fontSize={"20px"}
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                            my: { xs: 1, sm: 2, md: 2, lg: 2 },
                          }}
                        >
                          <PlaceOutlinedIcon />
                          <Typography
                            variant="body1"
                            // fontSize={"20px"}
                            component="div"
                          >
                            {" "}
                            {singlecities},{singlestate}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body1"
                          // fontSize={"20px"}
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                            my: { xs: 1, sm: 2, md: 2, lg: 2 },
                          }}
                        >
                          <WorkOutlineOutlinedIcon />
                          <Typography
                            variant="body1"
                            // fontSize={"20px"}
                            component="div"
                          >
                            {minexp < 1 && !maxexp ? (
                              "Entry-Level"
                            ) : (
                              <>
                                {minexp >= 0 ? (
                                  <>
                                    {minexp}
                                    {!maxexp ? "+" : ""}
                                  </>
                                ) : null}
                                {maxexp ? ` - ${maxexp}` : ""}
                                {minexp === 1 && !maxexp
                                  ? " year"
                                  : minexp < 0 || maxexp
                                    ? " years"
                                    : ""}
                              </>
                            )}
                          </Typography>
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Typography sx={{ mb: 3 }}>
                    <h2 className="fw-400">About the Job</h2>
                    <div
                      style={{
                        paddingTop: "8px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: editorData ? editorData : "NA",
                      }}
                    />
                  </Typography>
                  <div>
                    <h2 className="fw-400">Skills</h2>
                    <Stack spacing={1} sx={{ pt: 1.5 }}>
                      <Stack direction="row" flexWrap={"wrap"} gap={1}>
                        {skills &&
                          skills?.map((item, index) => {
                            return (
                              <Chip
                                key={index}
                                label={item}
                                variant="filled"
                                sx={{ backgroundColor: "rgba(0, 0, 0, 0.87))" }}
                              />
                            );
                          })}
                      </Stack>
                    </Stack>
                  </div>
                  <Typography sx={{ mt: 3 }}>
                    <h2 className="fw-400">More Info</h2>
                    <Grid container sx={{ pt: 1 }}>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Job Type
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {jobType}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Role
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {singleCategory}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" gutterBottom>
                          <Typography
                            variant="body2"
                            color={"#343434"}
                            gutterBottom
                          >
                            Work Mode
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            gutterBottom
                          >
                            {singleworkmode}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleButtonClick}
                  disabled={loading} // Disable the button while loading
                >
                  {loading ? "Posting..." : "Post"}
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </FormControl>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={3000} // Adjust this as needed
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={
            snackbarMessage === "Job Created Successfully" ||
              snackbarMessage === "Job Edited Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Createjobdrawer;
