import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  InputLabel,
  Slider,
  Select,
  FormControl,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { styled } from "@mui/material/styles";
import axios from "axios";
import _debounce from "lodash/debounce";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Cookies from "js-cookie";
import CircularProgress from '@mui/material/CircularProgress';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "61vh",
        overflow: "auto",
      }}
    >
      {value === index && <Box sx={{ py: 2, px: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewAddCandidate = ({ Job_id, isAddToJob, setrefreshagain }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [dialogOpen, setDialogOpen] = useState({
    addCandidates: false,
    addSingleCandidate: false,
    addSingleCandidateTabs: false,
  });
  const [formData, setFormData] = useState({
    // Personal Details
    fullName: "",
    mobileNumber: "",
    email: "",
    gender: "",
    dob: "",
    currentAddress: "",
    LinkedIn: "",
    portfolio: "",
    // Experience Details
    years: "",
    months: "",
    currentEmployer: "",
    currentSalary: "",
    expectedSalary: "",
    currentLocation: "",
    highEducation: "",
    customWorkAuthorization: "",
    work_authorization: "",
    expectedJoiningDate: "",
    lastWorkingDay: "",
    noticePeriod: "",
    radius: 20,
    relocate: "preferredLocation", // default value
    preferredLocations: [],
    skills: [],
    skillsDisplay: [],
    preferredLocationsIDS: [],
    currentLocationIDS: "",
    isDragging: false, // for drag feature 
  });

  const [currentLocation, setCurrentLocation] = useState(null); //for fetched location list
  const [preferedLocation, setpreferedLocation] = useState(null);
  const [HighEducationOptions, setHighEducationOptions] = useState([]); //for fetched education list
  const [workAuthorizationOptions, setWorkAuthorizationOptions] = useState([]); //for fetched work authorization list
  const [getCity, setCityLoc] = useState("");
  const [getprefered, setprefcityLoc] = useState([]);

  //snackbar states
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  //error states
  const [errors, setErrors] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    gender: "",
    dob: "",
    currentAddress: "",
    LinkedIn: "",
    portfolio: "",
    // Experience Details
    years: "",
    months: "",
    currentEmployer: "",
    currentSalary: "",
    expectedSalary: "",
    currentLocation: "",
    highEducation: "",
    customWorkAuthorization: "",
    work_authorization: "",
    expectedJoiningDate: "",
    lastWorkingDay: "",
    noticePeriod: "",
    radius: "",
    customWorkAuthorization: "",
    preferredLocations: "",
    skills: "",
    resume: "",
  });
  const genders = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Prefer not to respond" },
  ];
  const [resume, setResume] = useState(null);
  const [selectedworkAuthorization, setSelectedworkAuthorization] = useState(
    []
  );
  const [SkillsOption, setSkillsOption] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleOpenDialog = (dialogKey) => {
    setDialogOpen({
      addCandidates: dialogKey === "addCandidates",
      addSingleCandidate: dialogKey === "addSingleCandidate",
      addSingleCandidateTabs: dialogKey === "addSingleCandidateTabs",
    });
  };

  const handleCloseDialog = (dialogKey, reason) => {
    if (reason !== "backdropClick") {
      setDialogOpen((prev) => ({ ...prev, [dialogKey]: false }));
      if (dialogKey === "addSingleCandidateTabs") {
        setFormData({
          // Personal Details
          fullName: "",
          mobileNumber: "",
          email: "",
          gender: "",
          dob: "",
          currentAddress: "",
          LinkedIn: "",
          portfolio: "",
          // Experience Details
          years: "",
          months: "",
          currentEmployer: "",
          currentSalary: "",
          expectedSalary: "",
          currentLocation: "",
          highEducation: "",
          customWorkAuthorization: "",
          work_authorization: "",
          expectedJoiningDate: "",
          lastWorkingDay: "",
          noticePeriod: "",
          radius: 20,
          relocate: "preferredLocation", // default value
          preferredLocations: [],
          skills: [],
          skillsDisplay: [],
          preferredLocationsIDS: [],
          currentLocationIDS: "",
        });
        setErrors({
          fullName: "",
          mobileNumber: "",
          email: "",
          gender: "",
          dob: "",
          currentAddress: "",
          LinkedIn: "",
          portfolio: "",
          // Experience Details
          years: "",
          months: "",
          currentEmployer: "",
          currentSalary: "",
          expectedSalary: "",
          currentLocation: "",
          highEducation: "",
          customWorkAuthorization: "",
          work_authorization: "",
          expectedJoiningDate: "",
          lastWorkingDay: "",
          noticePeriod: "",
          radius: "",
          customWorkAuthorization: "",
          preferredLocations: "",
          skills: "",
        });
        setSnackbarMessage();
        setSelectedworkAuthorization([]);
        setSkillsOption([]);
        setSnackbarOpen(false);
        setTabValue(0);
        setResume(null);
        setCityLoc("");
        setprefcityLoc([]);
        setprefcityLoc([]);
        setCityLoc("");
        setWorkAuthorizationOptions([]);
        setCurrentLocation(null);
        setHighEducationOptions([]);
      }
    }
  };

  const handleSingleCandidateTab = (event, newValue) => {
    const { fullName, mobileNumber, email } = formData;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Step 1: Basic validation (form data fields and email format)
    if (!fullName) {
      setErrors((prev) => ({ ...prev, fullName: "This field is required" }));
      return;
    }
    if (!mobileNumber) {
      setErrors((prev) => ({
        ...prev,
        mobileNumber: "This field is required",
      }));
      return;
    }

    //email validation 
    if (!email) {
      setErrors((prev) => ({ ...prev, email: "This field is required" }));
      return;
    }
    else if (!emailPattern.test(email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
      return;
    }

    // Step 2: Work Authorization validation (if required based on tabValue)
    if (
      selectedworkAuthorization.work_authorization === "Others" &&
      tabValue === 1
    ) {
      if (!formData.customWorkAuthorization) {
        setErrors((prev) => ({
          ...prev,
          customWorkAuthorization: "This field is required",
        }));
        return;
      } else {
        setErrors((prev) => ({ ...prev, customWorkAuthorization: "" }));
      }
    }

    // Step 3: Preferred Locations validation (if required based on tabValue)
    if (
      formData.relocate === "preferredLocation" &&
      formData.preferredLocations.length === 0 &&
      tabValue === 2
    ) {
      setErrors((prev) => ({
        ...prev,
        preferredLocations: "Please Select Locations",
      }));
      return;
    }

    // Step 4: Closing dialog if tabValue is -1
    if (tabValue === -1) {
      return;
    }

    // Step 5: Handling tab navigation logic
    if (newValue === 4) {
      // If next tab value is 4, handle submit and validation here
      if (validate()) {
        if (formData.skills?.length === 0 && newValue === 4) {
          setErrors({
            ...errors,
            skills: "Skills are required.",
          });
        } else if (formData.skills.length < 5) {
          setErrors({
            ...errors,
            skills: "Minimum 5 skills are required.",
          });
        } else {
          setErrors({
            ...errors,
            skills: "",
          });
          CreateCandidate(); // Call CreateCandidate after validation passes
        }
      }
      return;
    }

    //step 6

    // Proceed to next tab otherwise
    if (validate()) {
      setTabValue(newValue);
    }
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];

    const maxSize = 10 * 1024 * 1024; // 10MB in bytes

    // Check if the file type is allowed
    if (!allowedTypes.includes(file.type)) {
      setErrors({
        ...errors,
        resume: "Please select a valid file type: txt, Docx, or PDF file.",
      });
      setSnackbarMessage("Please select a valid file type: txt, Docx, or PDF file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // Check if the file size exceeds the limit
    if (file.size > maxSize) {
      setErrors({
        ...errors,
        resume: "File size must be less than 10MB.",
      });
      setSnackbarMessage("File size must be less than 10MB.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setSnackbarMessage("File uploaded successfully.");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    // If both checks pass, set the resume file and open the dialog
    setResume(file);
    handleOpenDialog("addSingleCandidateTabs");
    setErrors({
      ...errors,
      fullName: "",
      resume: "",
      mobileNumber: "",
      email: "",
      gender: "",
      dob: "",
      currentAddress: "",
      LinkedIn: "",
      portfolio: "",
      // Experience Details
      years: "",
      months: "",
      currentEmployer: "",
      currentSalary: "",
      expectedSalary: "",
      currentLocation: "",
      highEducation: "",
      customWorkAuthorization: "",
      work_authorization: "",
      expectedJoiningDate: "",
      lastWorkingDay: "",
      noticePeriod: "",
      radius: "",
      customWorkAuthorization: "",
      preferredLocations: "",
      skills: "",
    });

    const formData = new FormData();
    formData.append("resume", file);
    // setLoader(true);
    axios
      .post(`/parse-resume`, formData, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        // setLoader(false);
        const { data: { message = {} } = {} } = response;
        const name =
          message.name.charAt(0).toUpperCase() +
          message.name.slice(1).toLowerCase();
        setFormData((prevFormData) => ({
          ...prevFormData,
          fullName: name || prevFormData.fullName,
          email: message.email || prevFormData.email,
          mobileNumber: message.phone || prevFormData.mobileNumber,
          currentAddress: message.address
            ? message.address.join(", ")
            : prevFormData.currentAddress,
          LinkedIn: message.linkedin || prevFormData.LinkedIn,
          years: message.experienceYear || prevFormData.years,
          months: message.experienceMonth || prevFormData.months,
        }));
      });
  };

  // Get today's date in YYYY-MM-DD format to restrict DOB selection
  const today = new Date().toISOString().split("T")[0];
  // Set the minimum allowable date to a reasonable past date
  const minDate = "1980-01-01";

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prepare a new formData object to be updated
    let updatedFormData = { ...formData };
    let updatedErrors = { ...errors };

    // Handle special cases for fields
    switch (name) {
      case "currentLocation": {
        // Handle the currentLocation logic
        const selectedLocation = currentLocation.find(
          (loc) => loc.city === value
        );

        if (selectedLocation) {
          updatedFormData.currentLocation = selectedLocation.city;
          updatedFormData.currentLocationIDS = value;
        } else {
          updatedFormData.currentLocationIDS = "";
          updatedFormData.currentLocation = ""; // Clear location if not found
        }
        break;
      }
      case "noticePeriod": {
        if (/^\d{0,2}$/.test(value)) {
          updatedFormData.noticePeriod = value;
          updatedErrors.noticePeriod = "";
        }
      }
      case 'LinkedIn': {
        const LinkedIn = "^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+\/?$";
        if (!value.match(LinkedIn)) {
          updatedFormData.LinkedIn = "";
          updatedErrors.LinkedIn = "Invalid LinkedIn URL";
        }
        else {
          updatedFormData.LinkedIn = value;
          updatedErrors.LinkedIn = "";
        }
        break;
      }
      case "relocate": {
        // Handle the relocate logic
        updatedFormData.relocate = value;
        updatedErrors.relocate = ""; // Clear relocate error
        updatedErrors.preferredLocations = ""; // Clear preferredLocations error
        break;
      }

      case "mobileNumber": {
        // Ensure only digits are allowed in mobile number
        if (!/^\d*$/.test(value)) {
          return; // Prevent input if it's not a digit
        }
        updatedFormData.mobileNumber = value;
        updatedErrors.mobileNumber = ""; // Clear mobileNumber error
        break;
      }

      case "work_authorization": {
        // Handle work authorization logic
        const selectedOption = workAuthorizationOptions.find(
          (option) => option.work_authorization === value
        );
        if (selectedOption) {
          setSelectedworkAuthorization(selectedOption);
          updatedFormData.work_authorization =
            selectedOption.work_authorization_id;
          updatedErrors.customWorkAuthorization = ""; // Clear customWorkAuthorization error
        }
        break;
      }
      case "years": {
        if (!/^\d*$/.test(value)) {
          return; // Prevent input if it's not a digit
        }
        // Update the years field first
        updatedFormData.years = value;
        // Check if the value is numeric and within limits
        const numericValue = value ? Number(value) : 0; // Avoid parsing empty strings
        if (numericValue > 40) {
          updatedErrors.years = "Max year is 40";
        } else {
          updatedErrors.years = ""; // Clear the error if valid
        }
        break;
      }

      // For all other fields, update normally
      default: {
        updatedFormData[name] = value;
        updatedErrors[name] = ""; // Clear errors for the updated field (if any)
        break;
      }
    }

    // Finally, update the form data and errors state
    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };

  const validate = () => {
    let tempErrors = { ...errors };
    let valid = true;

    // Full Name validation
    if (!formData.fullName.trim()) {
      tempErrors.fullName = "Full Name is required";
      valid = false;
    } else {
      tempErrors.fullName = "";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Invalid email format";
      valid = false;
    } else {
      tempErrors.email = "";
    }

    // Mobile Number validation - must be exactly 10 digits
    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.mobileNumber) {
      tempErrors.mobileNumber = "Mobile number is required";
      valid = false;
    } else if (!phoneRegex.test(formData.mobileNumber)) {
      tempErrors.mobileNumber = "Mobile number must be exactly 10 digits";
      valid = false;
    } else {
      tempErrors.mobileNumber = "";
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      return true;
    } else {
      return false;
    }
  };
  const CreateCandidate = async () => {
    // Ensure currentLocation is valid and formData.currentLocation is provided
    const selectedCity = (currentLocation || []).find(
      (city) => city.city === formData.currentLocation
    );
    const cityId = selectedCity ? selectedCity.city_id : null;

    const payloadFormData = new FormData();
    payloadFormData.append("job_id", Job_id);
    payloadFormData.append("resume", resume);
    payloadFormData.append("email", formData.email);
    payloadFormData.append("fullname", formData.fullName);
    payloadFormData.append("mobile", formData.mobileNumber);
    payloadFormData.append("address", formData.currentAddress);
    payloadFormData.append("gender", formData.gender);
    payloadFormData.append("dob", formData.dob);
    payloadFormData.append("countryCode", "+1 ");
    payloadFormData.append("year", formData.years);
    payloadFormData.append("month", formData.months);
    payloadFormData.append("current_employer", formData.currentEmployer);
    payloadFormData.append("CTC", formData.currentSalary);
    payloadFormData.append("Exp_CTC", formData.expectedSalary);
    payloadFormData.append("radius", formData.radius);
    payloadFormData.append(
      "highest_educ",
      formData.highEducation ? formData.highEducation : 1
    );
    payloadFormData.append(
      "work_authorization",
      formData.work_authorization ? formData.work_authorization : 1
    );
    payloadFormData.append(
      "work_authorization_text",
      formData.customWorkAuthorization ? formData.customWorkAuthorization : null
    );
    payloadFormData.append(
      "expected_joining_date",
      formData.expectedJoiningDate ? formData.expectedJoiningDate : null
    );
    payloadFormData.append(
      "notice_period",
      formData.noticePeriod ? formData.noticePeriod : null
    );
    payloadFormData.append(
      "last_working_day",
      formData.lastWorkingDay ? formData.lastWorkingDay : null
    );
    payloadFormData.append("linkedin", formData.LinkedIn);
    payloadFormData.append("portfolio", formData.portfolio);
    // Collect the skill values in an array
    const skillsArray = formData.skills.map((skill) => skill.label);
    // Append the entire array of skills under the key 'skills'
    const skillsString = skillsArray.join(",");
    payloadFormData.append("skill", JSON.stringify(skillsString));
    payloadFormData.append("current_loc", cityId);
    payloadFormData.append(
      "preferred_loc",
      formData.relocate === "preferredLocation" &&
        formData.preferredLocationsIDS.length > 0
        ? formData.preferredLocationsIDS.length
        : formData.relocate === "anyLocation"
          ? 0
          : "Any Location"
    );
    payloadFormData.append("isAddToJob", isAddToJob);
    setLoader(true);
    axios
      .post(`/create-candidate`, payloadFormData, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setSnackbarMessage("Candidate Added Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setTimeout(() => {
            handleCloseDialog("addSingleCandidateTabs");
            setrefreshagain(true);
          }, 300);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        setSnackbarMessage(err?.response?.data?.error);
        setSnackbarSeverity("error");
        if (err?.response?.data?.error === "Email Already Exist") {
          setTabValue(0);
        }
        setSnackbarOpen(true);
      });
  };

  const fetchData = async () => {
    try {
      if (getCity && getCity.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getCity,
          }
        );
        response.data.message && response.data.message.length > 0
          ? setCurrentLocation(response.data.message)
          : setCurrentLocation(currentLocation);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const preferedfetchData = async () => {
    try {
      if (getprefered && getprefered.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getprefered,
          }
        );

        setpreferedLocation(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    // Call the preferred fetchData function if getprefered changes
    preferedfetchData();
    // Call the regular fetchData function if cityName changes
    fetchData();
  }, [getprefered, getCity]);

  useEffect(() => {
    // Fetch highest education options
    if (!dialogOpen.addSingleCandidateTabs) {
      return;
    }
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/highest-education`)
      .then((response) => {
        setHighEducationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });

    // Fetch work authorization options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/work-authorization`)
      .then((response) => {
        setWorkAuthorizationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching work authorization options:", error);
      });
  }, [dialogOpen.addSingleCandidateTabs]);

  const FetchCandidateSkills = async (val) => {
    try {
      if (val) {
        const response = await axios.get(
          process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`
        );
        setSkillsOption(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const availableOptions = SkillsOption.filter(
    (option) => !formData.skills.includes(option.label.toLowerCase())
  );
  const handleSkillInputChange = _debounce((value) => {
    FetchCandidateSkills(value);
  }, 415);

  const handleSkills = (_, newValues) => {
    if (!Array.isArray(newValues)) {
      console.error("Invalid value for skills:", newValues);
      return;
    }
    // Extract skill labels if they are objects, otherwise use the value directly
    const labels = newValues.map((item) =>
      typeof item === "object" && item.hasOwnProperty("label") ? item : item
    );
    // Remove duplicates from the skills (both from the current formData and new values)
    if (labels.length > 0) {
      const uniqueSkills = [
        ...new Set([
          ...formData.skills.map((skill) => skill), // Existing skills
          ...labels.map((label) => label), // New skills
        ]),
      ];
      setFormData({
        ...formData,
        skills: uniqueSkills, // For actual data submission
      });
    } else {
      setFormData({
        ...formData,
        skills: newValues, // For actual data submission
      });
    }

    setErrors((prev) => ({ ...prev, skills: "" }));
  };
  const handleLocationChange = (event, val) => {
    // console.log(val);
    // console.log(event);
    if (val.length <= 5) {
      // Find the location IDs based on the selected location names
      const selectedIds = val
        .map((location) => {
          const matchedLocation = preferedLocation.find(
            (item) => item.city === location
          );
          return matchedLocation ? matchedLocation.city_id : null;
        })
        .filter((id) => id !== null); // Filter out any null values

      // Update formData with both preferredLocations and preferredLocationsIDS in one call
      setFormData((prev) => ({
        ...prev,
        preferredLocations: val,
        preferredLocationsIDS: selectedIds,
      }));

      // Clear any errors for preferredLocations
      setErrors((prev) => ({ ...prev, preferredLocations: "" }));
    } else {
      // Set an error if more than 5 locations are selected
      setErrors((prev) => ({
        ...prev,
        preferredLocations: "Maximum 5 locations allowed",
      }));
    }
  };
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormData((prev) => ({ ...prev, isDragging: true }));
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormData((prev) => ({ ...prev, isDragging: true }));
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormData((prev) => ({ ...prev, isDragging: false }));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormData((prev) => ({ ...prev, isDragging: false }));
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      handleResumeChange({ target: { files: [file] } });
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        size="small"
        startIcon={<PersonAddIcon />}
        onClick={() => handleOpenDialog("addSingleCandidate")}
      >
        Add Candidate
      </Button>

      <Dialog
        open={dialogOpen.addCandidates}
        onClose={() => handleCloseDialog("addCandidates")}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            width: "580px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 3,
            py: 2,
            color: "#101828",
            fontSize: "22px",
            fontWeight: "500",
            borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <Typography>Add Candidate</Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleCloseDialog("addCandidates")}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              p: 3,
              width: "100%",
              borderRight: "1px solid rgba(0, 0, 0, 0.10)",
            }}
          >
            <Box
              sx={{
                height: "216px",
                padding: "6.4px 15px",
                borderRadius: 2,
                background: "#F4FAFF",
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                cursor: "pointer",
                transition: "background 0.4s ease-in",
                "&:hover": {
                  background: "#006CB7",
                  "& .addIndividual_icon,& .addIndividual_text": {
                    color: "#fff",
                  },
                },
              }}
              onClick={() => handleOpenDialog("addSingleCandidate")}
            >
              <PersonAddIcon
                className="addIndividual_icon"
                sx={{
                  color: "#006CB7",
                  fontSize: "64px",
                  transition: "color 0.25s ease-in",
                }}
              />
              <Typography
                className="addIndividual_text"
                sx={{
                  color: "#424242",
                  fontSize: "22px",
                  textAlign: "center",
                  transition: "color 0.25s ease-in",
                }}
              >
                Add Individual Candidates
              </Typography>
            </Box>
          </Box>
          <Box sx={{ p: 3, width: "100%" }}>
            <Box
              sx={{
                height: "216px",
                padding: "6.4px 15px",
                borderRadius: 2,
                background: "#F4FAFF",
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                cursor: "pointer",
                transition: "background 0.4s ease-in",
                "&:hover": {
                  background: "#006CB7",
                  "& .addMultiple_icon,& .addMultiple_text": {
                    color: "#fff",
                  },
                },
              }}
            >
              <GroupAddIcon
                className="addMultiple_icon"
                sx={{
                  color: "#006CB7",
                  fontSize: "64px",
                  transition: "color 0.25s ease-in",
                }}
              />
              <Typography
                className="addMultiple_text"
                sx={{
                  color: "#424242",
                  fontSize: "22px",
                  textAlign: "center",
                  transition: "color 0.25s ease-in",
                }}
              >
                Add Multiple Candidates
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={dialogOpen.addSingleCandidate}
        onClose={() => handleCloseDialog("addSingleCandidate")}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            width: "580px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px 24px",
            color: "#101828",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.10)"
          }}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
            Add Candidate
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleCloseDialog("addSingleCandidate")}
          >
            <CloseIcon />
          </Box>
        </Box>

        <Box sx={{ p: 4, display: "flex", flexDirection: "column", gap: 3.5 }}>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "700",
                color: "#0F0F0F",
              }}
            >
              Upload file
            </Typography>
            <Typography
              sx={{ textAlign: "center", fontSize: "12px", color: "#616161" }}
            >
              Please select relevant document to complete process
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "40px 100px",
              borderRadius: 2.5,
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              backgroundColor: isDragging ? "#e0f7fa" : "transparent", // Change color on drag
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <UploadFileIcon sx={{ color: "#0F91D2", fontSize: "32px" }} />
              <Typography sx={{ color: "#0F0F0F" }}>
                Select a file or drag and drop here
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#616161",
                }}
              >
                txt, docx & pdf file size no more than{" "}
                <span style={{ color: "#E25C72" }}>10MB</span>
              </Typography>
              {errors.resume && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#E25C72",
                  }}
                >
                  {errors.resume}
                </Typography>
              )}
            </Box>
            <Button
              component="label"
              variant="contained"
              sx={{ mt: 2.5, width: "100%", textTransform: "capitalize" }}
            >
              Select file
              <VisuallyHiddenInput
                type="file"
                onChange={handleResumeChange}
                accept=".pdf, .docx, text/plain"
              />
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={dialogOpen.addSingleCandidateTabs}
        onClose={(e, reason) => handleCloseDialog("addSingleCandidateTabs", reason)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px 24px",
            color: "#101828",
            width: "100%",
            height: "40px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
            }}
          >
            Add Candidate
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleCloseDialog("addSingleCandidateTabs")}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#F7F8FA",
              padding: "10px 24px 0px 24px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleSingleCandidateTab}
              variant="fullWidth"
              aria-label="basic tabs example"
            >
              <Tab label="RESUME" {...a11yProps(0)} />
              <Tab label="EXPERIENCE" {...a11yProps(1)} />
              <Tab label="RELOCATION" {...a11yProps(2)} />
              <Tab label="SKILLS" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form
              onSubmit={handleSubmit}
              borderRadius={2}
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              {/* Show loader when loader state is true */}
              {loader && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <CustomTabPanel
                value={tabValue}
                index={0}
                sx={{ flexGrow: 1, padding: "16px 24px 8px 24px" }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={"18px"}
                  letterSpacing={0.5}
                  mb={2}
                >
                  Resume*
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  {/* First Box (File Name Display) */}
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      padding: "12px 16px",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1, // Ensure this Box takes up remaining space
                    }}
                  >
                    <InsertDriveFileOutlinedIcon
                      sx={{
                        color: "#0F91D2",
                        verticalAlign: "middle",
                        marginRight: "8px",
                      }}
                    />
                    {resume ? (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          fontStyle: "normal",
                          lineHeight: "24px",
                          color: "black",
                          letterSpacing: "0.5px",
                          flexGrow: 1, // Ensure Typography takes full width
                        }}
                      >
                        {resume.name.length > 30
                          ? `${resume.name.substring(0, 30)}...`
                          : resume.name}
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        No resume data available
                      </Typography>
                    )}
                  </Box>

                  {/* Second Box (Upload New Button) */}
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      padding: "12px 16px",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      flexShrink: 0, // Prevent shrinking of this Box
                    }}
                  >
                    {/* Hidden input file */}
                    <input
                      accept="*"
                      id="file-upload"
                      type="file"
                      onChange={handleResumeChange}
                      style={{ display: "none" }} // Use inline style to ensure it remains hidden
                    />

                    {/* Label triggers file input */}
                    <label htmlFor="file-upload">
                      <Button
                        variant="none"
                        size="small"
                        component="span"
                        fontWeight={600}
                        sx={{
                          color: "#0F91D2",
                          display: "flex",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        startIcon={
                          <FileUploadOutlinedIcon
                            sx={{
                              color: "#0F91D2",
                              verticalAlign: "middle",
                            }}
                          />
                        }
                      >
                        Upload New
                      </Button>
                    </label>
                  </Box>
                </Box>
                <Grid container spacing={2} borderRadius={2}>
                  <Grid item xs={12} mt={"18px"} mb={"18px"} borderRadius={2}>
                    <TextField
                      size="small"
                      label="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      error={!!errors.fullName}
                      helperText={errors.fullName}
                      required
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      borderRadius={2}
                      size="small"
                      label="Mobile Number"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      error={!!errors.mobileNumber}
                      helperText={errors.mobileNumber}
                      required
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*", // Allow only numeric input
                        maxLength: 10, // Limit input to 10 characters
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      size="small"
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      required
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      select
                      size="small"
                      label="Gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "21px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                        "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "21px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    >
                      {genders.map((option) => (
                        <MenuItem
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "21px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "21px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      size="small"
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      value={formData.dob}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.dob}
                      helperText={errors.dob}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                      inputProps={{
                        max: "2006-10-09", // Prevent future dates
                        min: minDate, // Set a reasonable minimum date
                      }}
                    />
                  </Grid>

                  {/* current address */}
                  <Grid item xs={12} mb={"18px"}>
                    <TextField
                      size="small"
                      label="Current Address"
                      name="currentAddress"
                      value={formData.currentAddress}
                      onChange={handleChange}
                      multiline
                      rows={3}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  {/* linkedin URL  */}
                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      size="small"
                      label="Linkedin URL"
                      name="LinkedIn"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      value={formData.LinkedIn}
                      onChange={handleChange}
                      error={!!errors.LinkedIn}
                      helperText={errors.LinkedIn}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} mb={"18px"}>
                    <TextField
                      size="small"
                      label="Portfolio URL"
                      name="portfolio"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      value={formData.portfolio}
                      onChange={handleChange}
                      error={!!errors.portfolio}
                      helperText={errors.portfolio}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1} sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  mb={1}
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    letterSpacing: "0.5px",
                  }}
                >
                  Experience
                </Typography>
                <Grid container spacing={2}>
                  {/* Years and Months of Experience */}
                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      label="Years"
                      name="years"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      value={formData.years}
                      onChange={handleChange}
                      error={!!errors.years}
                      helperText={errors.years}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*", // Allow only numeric input
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      select
                      label="Months"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      name="months"
                      value={formData.months}
                      onChange={handleChange}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "21px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                        "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "21px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    >
                      {Array.from({ length: 12 }).map((_, index) => (
                        <MenuItem
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "21px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "21px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                          }}
                          key={index}
                          value={index}
                        >
                          {index} month{index === 1 ? "" : "s"}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* Current Employer */}
                  <Grid item xs={12} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Current Employer"
                      name="currentEmployer"
                      value={formData.currentEmployer}
                      onChange={handleChange}
                      error={!!errors.currentEmployer}
                      helperText={errors.currentEmployer}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  {/* Current Salary and Expected Salary */}
                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Current Salary"
                      name="currentSalary"
                      value={formData.currentSalary}
                      onChange={handleChange}
                      error={!!errors.currentSalary}
                      helperText={errors.currentSalary}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Expected Salary"
                      name="expectedSalary"
                      value={formData.expectedSalary}
                      onChange={handleChange}
                      error={!!errors.expectedSalary}
                      helperText={errors.expectedSalary}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>

                  {/* Current Location */}
                  <Grid item xs={12} mb={"8px"}>
                    <Autocomplete
                      size="small"
                      disablePortal
                      fullWidth
                      value={formData.currentLocation}
                      // size="small"
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue.length >= 1) {
                          setTimeout(() => {
                            setCityLoc(newInputValue);
                            setFormData({
                              ...formData,
                              currentLocation: newInputValue,
                            });
                          }, 100);
                        } else {
                          setCityLoc("");
                          setFormData({
                            ...formData,
                            currentLocation: newInputValue,
                          });
                        }
                      }}
                      onChange={handleChange}
                      name="currentLocation"
                      id="controllable-states-demo"
                      options={
                        (currentLocation &&
                          currentLocation?.map((ele) => ele.city)) ||
                        []
                      }
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent form submission on Enter
                            }
                          }}
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "24px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                          }}
                          {...params}
                          label="Current Location"
                        />
                      )}
                    />
                  </Grid>

                  {/* highest Education field */}
                  <Grid item xs={12} mb={"8px"}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "black",
                          letterSpacing: "0.5px",
                        },
                      }}
                    >
                      <InputLabel size="small" id="demo-simple-select-label">
                        Highest Education
                      </InputLabel>
                      <Select
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "black",
                            letterSpacing: "0.5px",
                          },
                        }}
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.highEducation}
                        name="highEducation"
                        onChange={handleChange}
                        label="Highest Education" // This connects the label with the select
                      >
                        {HighEducationOptions.map((options) => (
                          <MenuItem
                            sx={{
                              width: "100%",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                                letterSpacing: "0.5px",
                              },
                            }}
                            key={options.highest_education_id} // Ensure to add a key
                            className="fw-bold"
                            value={options.highest_education_id}
                          >
                            {options.highest_education}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* work authorization */}
                  <Grid
                    item
                    xs={
                      selectedworkAuthorization.work_authorization === "Others"
                        ? 6
                        : 12
                    }
                    mb={"8px"}
                  >
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "black",
                          letterSpacing: "0.5px",
                        },
                      }}
                    >
                      <InputLabel size="small" id="demo-simple-select-label">
                        Work Authorization
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedworkAuthorization.work_authorization}
                        name="work_authorization"
                        onChange={handleChange}
                        label="Work Authorization"
                      >
                        {workAuthorizationOptions.map((options) => (
                          <MenuItem
                            sx={{
                              width: "100%",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "black",
                                letterSpacing: "0.5px",
                              },
                            }}
                            key={options.work_authorization_id}
                            value={options.work_authorization}
                          >
                            {options.work_authorization}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Other Work Authorization Field (Conditional) */}
                  {selectedworkAuthorization.work_authorization ===
                    "Others" && (
                      <Grid item xs={6} sx={{ marginTop: "-16px" }} mb={"8px"}>
                        <TextField
                          size="small"
                          fullWidth
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent form submission on Enter
                            }
                          }}
                          label="Other Work Authorization"
                          name="customWorkAuthorization"
                          value={formData.customWorkAuthorization}
                          onChange={handleChange}
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px !important",
                              fontWeight: "400 !important",
                              fontSize: "14px !important",
                              fontStyle: "normal !important",
                              lineHeight: "24px !important",
                              color: "black !important",
                              letterSpacing: "0.5px !important",
                            },
                          }}
                          error={errors.customWorkAuthorization ? true : false}
                          helperText={
                            errors.customWorkAuthorization
                              ? "This field is required"
                              : ""
                          }
                        />
                      </Grid>
                    )}

                  {/* Last Working Day and Expected Joining Date */}
                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Last Working Day"
                      name="lastWorkingDay"
                      type="date"
                      value={formData.lastWorkingDay}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.lastWorkingDay}
                      helperText={errors.lastWorkingDay}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                      inputProps={{
                        max: today, // Prevent future dates
                        min: minDate, // Set a reasonable minimum date
                      }}
                    />
                  </Grid>

                  {/* Expected Joining Date */}
                  <Grid item xs={6} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Expected Joining Date"
                      name="expectedJoiningDate"
                      type="date"
                      value={formData.expectedJoiningDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.expectedJoiningDate}
                      helperText={errors.expectedJoiningDate}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                      inputProps={{
                        max: today, // Prevent future dates
                        min: minDate, // Set a reasonable minimum date
                      }}
                    />
                  </Grid>

                  {/* Notice Period */}
                  <Grid item xs={12} mb={"8px"}>
                    <TextField
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter
                        }
                      }}
                      label="Notice Period in Days"
                      name="noticePeriod"
                      value={formData.noticePeriod}
                      onChange={handleChange}
                      error={!!errors.noticePeriod}
                      helperText={errors.noticePeriod}
                      // required
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px !important",
                          fontWeight: "400 !important",
                          fontSize: "14px !important",
                          fontStyle: "normal !important",
                          lineHeight: "24px !important",
                          color: "black !important",
                          letterSpacing: "0.5px !important",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {/* Ready to Relocate */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginBottom: "8px",
                        fontWeight: 600,
                        fontSize: "18px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Ready to Relocate*
                    </Typography>
                    <RadioGroup
                      row
                      name="relocate"
                      value={formData.relocate}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="preferredLocation"
                        control={<Radio />}
                        label="Preferred Location"
                      />
                      <FormControlLabel
                        value="anyLocation"
                        control={<Radio />}
                        label="Any Location"
                      />
                    </RadioGroup>
                  </Grid>

                  {/* Preferred Location Field (Conditional) */}
                  <Grid item xs={12}>
                    {formData.relocate === "preferredLocation" ? (
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            marginBottom: "8px",
                            fontWeight: 600,
                            fontSize: "18px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Preferred Location
                        </Typography>
                        <Autocomplete
                          name="preferredLocations"
                          sx={{ mt: 2, mb: 3 }}
                          multiple
                          value={formData.preferredLocations}
                          onChange={handleLocationChange}
                          onBlur={() =>
                            setErrors((prev) => ({
                              ...prev,
                              preferredLocations: '',
                            }))
                          }
                          onInputChange={(event, newInputValue) => {
                            // Fetch data when user types in the input field
                            if (newInputValue.length >= 1) {
                              setTimeout(() => {
                                setprefcityLoc(newInputValue);
                              }, 100);
                            } else {
                              setprefcityLoc("");
                            }
                          }}
                          options={
                            (preferedLocation &&
                              preferedLocation?.map((ele) => ele.city)) ||
                            []
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="filled"
                                {...getTagProps({ index })}
                                label={option}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault(); // Prevent form submission on Enter
                                }
                              }}
                              sx={{
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px !important",
                                  fontWeight: "400 !important",
                                  fontSize: "14px !important",
                                  fontStyle: "normal !important",
                                  lineHeight: "24px !important",
                                  color: "black !important",
                                  letterSpacing: "0.5px !important",
                                },
                              }}
                              {...params}
                              variant="outlined"
                              size="small"
                              placeholder={
                                formData.preferredLocations.length > 0
                                  ? "Add another location"
                                  : "Enter preferred locations"
                              }
                              helperText={errors.preferredLocations}
                              error={!!errors.preferredLocations}
                            />
                          )}
                        />

                        <Typography
                          variant="h6"
                          sx={{
                            marginBottom: "8px",
                            fontWeight: 600,
                            fontSize: "18px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Radius (In miles)*
                        </Typography>
                        <Grid item xs={12} mt={3}>
                          <Typography variant="body2">
                            <Slider
                              name="radius"
                              value={formData.radius}
                              onChange={handleChange}
                              min={0}
                              max={100}
                              size="small"
                              defaultValue={formData.radius}
                              aria-label="Small"
                              valueLabelDisplay="auto"
                            />
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3} sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "8px",
                    fontWeight: 600,
                    fontSize: "18px",
                    letterSpacing: "0.5px",
                  }}
                >
                  Skills*
                </Typography>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ mt: -1, mb: 3 }}
                      multiple
                      freeSolo
                      options={availableOptions}
                      value={formData.skills ? formData.skills : []}
                      filterSelectedOptions
                      onChange={handleSkills}
                      onInputChange={(event, newInputValue) => {
                        handleSkillInputChange(newInputValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            variant="filled"
                            label={option.label ? option.label : option}
                            {...getTagProps({ index })}
                            onDelete={() => {
                              const updatedSkills = formData.skills?.filter(
                                (skill) => skill !== option
                              );
                              setFormData({
                                ...formData,
                                skills: updatedSkills,
                              });
                            }}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <div>
                          <TextField
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent form submission on Enter
                              }
                            }}
                            sx={{
                              borderRadius: "10px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px !important",
                                fontWeight: "400 !important",
                                fontSize: "14px !important",
                                fontStyle: "normal !important",
                                lineHeight: "24px !important",
                                color: "black !important",
                                letterSpacing: "0.5px !important",
                              },
                            }}
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder={
                              formData.skills.length > 0
                                ? "Add another skills"
                                : "Enter Skills"
                            }
                            helperText={errors.skills}
                            error={!!errors.skills}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              {/* Spacer to push buttons to bottom */}
              <Box sx={{ flexGrow: 1 }}></Box>
              <Divider sx={{ margin: "10px 0 10px 0", width: "100%" }} />
              <Grid
                container
                spacing={2}
                width={"95%"}
                marginLeft={"9px"}
                marginRight={"auto"}
                sx={{
                  pb: 2,
                }}
              >
                {/* Navigation Buttons */}
                <Grid item xs={6} pt={0}>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      if (tabValue === 0) {
                        handleCloseDialog("addSingleCandidateTabs"); // Call handleClose if "Cancel" is showing
                      } else {
                        handleSingleCandidateTab(e, tabValue - 1); // Otherwise, call the existing function
                      }
                    }}
                    sx={{ width: "100%", textTransform: "capitalize" }}
                  >
                    {tabValue === 0 ? "Cancel" : "Back"}
                  </Button>
                </Grid>
                <Grid item xs={6} pt={0}>
                  <Button
                    onClick={(e) => handleSingleCandidateTab(e, tabValue + 1)}
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "100%",
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    {tabValue === 3 ? "Add" : "Next"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </Box>
  );
};

export default NewAddCandidate;
