import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import "../custom.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Chip,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import Cookies from "js-cookie";
import Backdrop from "./Backdrop";
import Loader from "./Loader";

const myTypes = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const statusColors = {
  Active: "primary",
  "On Hold": "default",
  Canceled: "error",
  Full: "warning",
  Closed: "error",
};

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Typography variant="body2">{params.row.id}</Typography>
      </a>
    ),
  },
  {
    field: "title",
    headerName: "Job Title",
    width: 300,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Typography
          variant="body2"
          title={params.row.title}
          textTransform={"capitalize"}
        >
          {params.row.title}
        </Typography>
      </a>
    ),
  },
  {
    field: "type",
    headerName: "Job Type",
    width: 180,
    valueGetter: (params) => myTypes[params.value] || "N/A",
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {myTypes[params.row.type] || "N/A"}
      </a>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Typography>
          <Chip
            size="small"
            label={params.row.status || "NA"}
            variant="outlined"
            color={statusColors[params.row.status] || "default"}
          />
        </Typography>
      </a>
    ),
  },
  {
    field: "date_created",
    headerName: "Created",
    width: 150,
    typeof: "date",
    valueGetter: ({ value }) =>
      value ? new Date(value).toLocaleDateString("en-US") : "N/A",
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {new Date(params.row.date_created).toLocaleDateString()}
      </a>
    ),
  },
  {
    field: "count",
    headerName: "Applications",
    width: 150,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {params.row.count ? params.row.count : 0}
      </a>
    ),
  },
  {
    field: "open_count",
    headerName: "Open Applications",
    width: 150,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {params.row.open_count ? params.row.open_count : 0}
      </a>
    ),
  },
  {
    field: "status_rejected_count",
    headerName: "Closed Applications",
    width: 150,
    renderCell: (params) => (
      <a
        href={`/jobOrders/${params.row.id}`}
        state={{
          id: params.row.id,
          title: params.row.title,
          type: params.row.type,
          status: params.row.status,
          date_created: params.row.date_created,
          count: params.row.count,
          openapplication: params.row.open_count,
        }}
        style={{ textDecoration: "none", color: "black" }}
      >
        {params.row.status_rejected_count
          ? params.row.status_rejected_count
          : 0}
      </a>
    ),
  },
];

const ActiveJobs = ({ isActive, isSuccess }) => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const getToken = useSelector((state) => state.user.token);
  const [activeJobs, setActiveJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get("search") || ""
  );
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1 || 0,
    pageSize: 25,
  });
  const [totalCount, setTotalCount] = useState(0);
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const AddJobPermissionStatus = PermissionStatus["400"];

  useEffect(() => {
    // Fetch jobs whenever paginationModel or searchQuery changes
    axios
      .post(
        `user/jobOrders/${paginationModel.page + 1}${
          searchQuery ? "?search=" + searchQuery : ""
        }`,
        { isActive, pageSize: paginationModel.pageSize },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        setActiveJobs(response.data.message.resp_alljob);
        setTotalCount(response.data.message.total_jobs);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [isActive, isSuccess, searchParams, searchQuery, paginationModel]);

  useEffect(() => {
    // Update paginationModel from searchParams when they change
    const page = Number(searchParams.get("page")) - 1 || 0;
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: page,
    }));
  }, [searchParams]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchParams({ page: 1, search: query }); // Reset to page 1 on search
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 })); // Reset to page 0 on search
  };

  const handlePostJob = () => {
    nav("/Postjob");
  };

  const handleRowClick = (params) => {
    const currentUrl = window.location.href;
    sessionStorage.setItem("JobpreviousUrl", currentUrl);
    nav(`/jobOrders/${params.row.id}`, {
      state: {
        id: params.row.id,
        title: params.row.title,
        type: params.row.type,
        status: params.row.status,
        date_created: params.row.date_created,
        count: params.row.count,
        openapplication: params.row.open_count,
      },
    });
  };

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
    setSearchParams({ page: model.page + 1, search: searchQuery });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          variant="standard"
          placeholder="Search Job Title"
          id="standard-search"
          type="search"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: "20px", width: "25%" }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Prevent form submission if inside a form
            }
          }}
        />

        {AddJobPermissionStatus?.add === 1 && (
          <Button
            startIcon={<AddIcon />}
            onClick={handlePostJob}
            variant="contained"
          >
            Post a Job
          </Button>
        )}
      </Box>
      {isLoading ? (
        <Loader />
      ) : activeJobs.length === 0 ? (
        <Backdrop
          backDropIcon={<WorkOffOutlinedIcon />}
          backDropText={"No Jobs Found"}
        />
      ) : (
        <Box sx={{ width: "100%" }}>
          <DataGrid
            rows={activeJobs}
            columns={columns}
            autoHeight
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[25, 50, 75, 100]}
            rowCount={totalCount}
            onRowClick={handleRowClick}
            className="data-grid-row-hover"
          />
        </Box>
      )}
    </>
  );
};

export default ActiveJobs;
